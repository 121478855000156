import { useEffect } from 'react';
import AboutUs from './components/AboutUs/AboutUs';
import Clients from './components/Clients/Clients';
import Contact from './components/Contact/Contact';
import DecorativeLine from './components/DecorativeLine/DecorativeLine';
import Hero from './components/Hero/Hero';
import Footer from './components/shared/Footer/Footer';
import Navbar from './components/shared/Navbar/Navbar';
import SectionComponent from './components/shared/SectionComponent/SectionComponent';
import sectionData from './data';
import AOS from 'aos';
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
    });
  }, []);
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <div className='sm:px-8 px-4 mx-auto overflow-hidden'>
        <div className='sm:pt-[3.5rem] pt-4'>
          <AboutUs />
        </div>
        <div className='sm:py-[3.5rem] py-4 flex flex-col sm:gap-[3.5rem] gap-4 overflow-hidden'>
          {sectionData.map((section, index) => (
            <SectionComponent
              key={index}
              name={section.name}
              title={section.title}
              subtitle={section.subtitle}
              paragraphs={section.paragraphs}
              bulletPoints={section.bulletPoints}
              backgroundColor={section.backgroundColor}
              titleColor={section.titleColor}
              paragraphColor={section.paragraphColor}
              bulletPointColor={section.bulletPointColor}
              subtitleColor={section.subtitleColor}
              backgroundColorListItem={section.backgroundColorListItem}
              borderRadius={section.borderRadius}
              borderRadiusMobile={section.borderRadiusMobile}
              sectionImage={section.sectionImage}
              sectionImageMobile={section.sectionImageMobile}
              leftPosition={section.leftPosition}
            />
          ))}
        </div>
        <div data-aos="zoom-in" className='max-w-[1920px] mx-auto'>
          <DecorativeLine />
        </div>
        <Clients />
        <div data-aos="zoom-in" className='max-w-[1920px] mx-auto'>
          <DecorativeLine />
        </div>
        <Contact />
        <div>
          <DecorativeLine />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
