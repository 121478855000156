import React from "react";
import styles from "./Hero.module.css";
import { downArrow, heroVideo } from "../../assets";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <section className={styles.mainContainer}>
      <video src={heroVideo} alt="video" autoPlay loop muted playsInline></video>
      <div className={styles.heroTitle}>
        <h4 className="sm:text-center text-right" data-aos="fade-up">
          Crafting Solutions
        </h4>
        <h4 className="sm:text-center text-left" data-aos="fade-up">
          Redefining Success
        </h4>
      </div>
      <Link to="aboutUs" className={styles.servicesCTA} data-aos="fade-down">
        <p>CHECK OUT OUR SERVICES</p>
        <img
          src={downArrow}
          alt="downArrow"
          className="cursor-pointer sm:w-20 w-12"
        />
      </Link>
    </section>
  );
};

export default Hero;
