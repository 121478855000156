import React from "react";
import styles from "./AboutUs.module.css";
import { Element } from "react-scroll";

const AboutUs = () => {
  return (
    <section className={styles.mainContainer} data-aos="fade-up">
      <Element className="hash-span-about absolute" name="aboutUs">&nbsp;</Element>
      <h5 data-aos="fade-up">
        Our Independent Media Agency Is Used To Breaking The Mold
      </h5>
      <div className={styles.mainContainerContent}>
        <p data-aos="fade-up">
          Blind Ferret is a Montreal-based Digital Media Agency founded in 2005.
        </p>
        <p data-aos="fade-up">
          Our marketing and creative professionals prioritize delivering
          exceptional, performance-driven results for our clients.
        </p>
        <p data-aos="fade-up">
          With over 15 years of experience in advertising and publishing, we
          specialize in{" "}
          <strong>
            Digital Strategy, Paid Media, Social Media Management, Branding,
          </strong>{" "}
          and <strong>full-service Creative.</strong>
        </p>
      </div>
      <h6 data-aos="fade-up">
        Let’s shape the future of the industry with your brand!
      </h6>
    </section>
  );
};

export default AboutUs;
