import React, { useEffect, useState } from "react";

import styles from "./Navbar.module.css";
import { menuIcon, navlogo } from "../../../assets";
import { Link } from "react-scroll";
import MenuOpened from "./components/MenuOpened";
import { animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const [isTransparent, setIsTransparent] = useState(true);
  const [isOpened, setisOpened] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && isTransparent) {
        setIsTransparent(false);
      } else if (window.scrollY === 0 && !isTransparent) {
        setIsTransparent(true);
      }
    };
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [isTransparent]);

  function handleOpenMenu() {
    setisOpened(true);
  }
  function handleCloseMenu() {
    setisOpened(false);
  }

  const scrollToTop = () =>{
    scroll.scrollToTop();
  }

  return (
    <nav
      className={`${styles.navbar} ${
        !isTransparent && "bg-[--purple]"
      } transition-background-color duration-500`}
    >
      <ul className="navItemsFont">
          <li onClick={scrollToTop}>
            <img src={navlogo} alt="logo" />
          </li>
        <Link
          to="digitalStrategy"
          name="digitalStrategy"
          smooth={true}
          duration={500}
          className="hidden lg:flex"
        >
          <li className="hidden lg:flex">DIGITAL STRATEGY</li>
        </Link>
        <Link
          className="hidden lg:flex"
          to="paidMedia"
          name="paidMedia"
          smooth={true}
          duration={500}
        >
          <li className="hidden lg:flex">PAID MEDIA</li>
        </Link>
        <Link
          to="creativeServices"
          name="creativeServices"
          smooth={true}
          duration={500}
          className="hidden lg:flex"
        >
          <li className="hidden lg:flex">CREATIVE SERVICES</li>
        </Link>
        <Link
          className="hidden lg:flex"
          to="socialMedia"
          name="socialMedia"
          smooth={true}
          duration={500}
        >
          <li className="hidden lg:flex">SOCIAL MEDIA</li>
        </Link>
        <Link
          className="hidden lg:flex"
          to="contactUs"
          name="contactUs"
          smooth={true}
          duration={500}
        >
          <li className="hidden lg:flex">
            <div className={styles.contactButton}>CONTACT US</div>
          </li>
        </Link>
      </ul>
      <div className={styles.menuIconDiv} onClick={handleOpenMenu}>
        <img src={menuIcon} alt="menu-icon" />
      </div>
      <div
        className={`fixed top-0 right-0 lg:w-2/5 w-full h-full bg-[--purple] bg-opacity-1 z-[10] ${
          isOpened
            ? "flex slide-in"
            : isOpened === ""
            ? "hidden"
            : "slide-out closed"
        }`}
      >
        <MenuOpened handleCloseMenu={handleCloseMenu} scrollToTop={scrollToTop} />
      </div>
    </nav>
  );
};

export default Navbar;
