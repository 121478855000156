import React from "react";
import styles from "./Contact.module.css";
import { logoVertical} from "../../assets";
import { Element } from "react-scroll";

const Contact = () => {
  return (
    <section
      className={`${styles.mainContainer} sm:px-16 px-6 pb-14 relative overflow-hidden max-w-[1920px] mx-auto`}
    >
      <Element className="hash-span-contact absolute" name="contactUs">
        &NBSP;
      </Element>
      <div className="flex flex-col">
        <h2 className="sectionTitle pb-6" data-aos="fade-right">
          CONTACT US
        </h2>
        <h5 className="pb-16" data-aos="fade-right">
          Interested In Working With Us?
        </h5>
        <div  data-aos="fade-right">
          <div className={styles.contactLineTop}></div>
          <iframe
            src="https://webforms.pipedrive.com/f/czGlDtoZSx6ku6CfL2ilOBACNK8ohpGKJs6JTAocLKMr1mrluKHOe3e08U4bz0JTvt"
            title="Embedded Website"
            className="w-full h-[780px] sm:h-[820px] aspect-[2/3]  object-cover"
          />
          <div className={styles.contactLineBottom}></div>
        </div>
      </div>
      <div className="flex flex-1 opacity-20 justify-center">
        <img src={logoVertical} alt="formLogo" data-aos="fade-left" />
      </div>
    </section>
  );
};

export default Contact;
