import { appleLogo, blizzardLogo, disneyLogo, gameloftLogo, netflixLogo, nintendoLogo, riotLogo, segaLogo, team17Logo, ubisoftLogo } from "./assets";

const clients = [
    {
        logo: appleLogo
    },
    {
        logo: netflixLogo
    },
    {
        logo: nintendoLogo
    },
    {
        logo: segaLogo
    },
    {
        logo: team17Logo
    },
    {
        logo: blizzardLogo
    },
    {
        logo: riotLogo
    },
    {
        logo: disneyLogo
    },
    {
        logo: ubisoftLogo
    },
    {
        logo: gameloftLogo
    },
]

export default clients;