import React from "react";
import style from "./Footer.module.css";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="flex sm:flex-row flex-col sm:gap-0 gap-8 sm:-mt-[3.125rem] -mt-8 justify-between items-center px-8 py-6">
      <p className="text-[#fec260] font-bold text-lg sm:hidden block">
      An{" "}
        <span className="text-[#fff]">
          <a
            href="https://www.influencemobile.com/"
            target="_blank"
            rel="noreferrer"
            className="border-b-[1px] border-b-transparent hover:border-b-[--gold] transition-colors duration-500"
          >
            INFLUENCE MOBILE
          </a>
        </span>{" "}
        Company
      </p>
      <div className="flex sm:justify-normal justify-center items-end gap-5 w-[220px]">
        <div>
          <a
            href="https://www.linkedin.com/company/blind-ferret"
            target="_blank"
            rel="noreferrer"
          >
            <div className={style.svgHover}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Icon_awesome-linkedin-in"
                  data-name="Icon awesome-linkedin-in"
                  d="M5.372,24H.4V7.977H5.372ZM2.882,5.791A2.9,2.9,0,1,1,5.763,2.882,2.906,2.906,0,0,1,2.882,5.791ZM24,24H19.03V16.2c0-1.859-.038-4.243-2.587-4.243-2.587,0-2.983,2.02-2.983,4.109V24H8.489V7.977h4.772v2.186h.07a5.228,5.228,0,0,1,4.708-2.588C23.075,7.575,24,10.891,24,15.2V24Z"
                  transform="translate(0 0)"
                  fill="#fff"
                />
              </svg>
            </div>
          </a>
        </div>
        <div>
          <Link to="contactUs" name="contactUs" smooth={true} duration={500}>
            <div className={style.svgHover}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="17"
                viewBox="0 0 20.948 16.459"
              >
                <path
                  id="Icon_ionic-md-mail"
                  data-name="Icon ionic-md-mail"
                  d="M21.2,5.625H4.245a2,2,0,0,0-2,2V20.089a2,2,0,0,0,2,2H21.2a2,2,0,0,0,2-2V7.62A2,2,0,0,0,21.2,5.625Zm-.249,4.24-8.23,5.486L4.494,9.865v-2l8.23,5.486,8.23-5.486Z"
                  transform="translate(-2.25 -5.625)"
                  fill="#fff"
                />
              </svg>
            </div>
          </Link>
        </div>
      </div>

      <p className="text-[#fec260] font-bold text-lg sm:block hidden">
        An{" "}
        <span className="text-[#fff]">
          <a
            href="https://www.influencemobile.com/"
            target="_blank"
            rel="noreferrer"
            className="border-b-[1px] border-b-transparent hover:border-b-[--gold] transition-colors duration-500"
          >
            INFLUENCE MOBILE
          </a>
        </span>{" "}
        Company
      </p>

      <div className="sm:text-right text-center flex flex-col">
        <p className="text-white opacity-65 font-medium text-base">
          Copyright {new Date().getFullYear()} Blind Ferret
        </p>
        <a href="https://aeximius.com/" target="_blank" rel="noreferrer">
          <p className="text-white opacity-50 font-medium text-sm hover:text-[--gold] hover:opacity-100 transition-all duration-500">
            Built by AEXIMIUS
          </p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
