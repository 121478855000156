import {
    creativeServices, creativeServicesMobile, digitalStrategy, digitalStrategyMobile, paidMedia,
    paidMediaMobile, socialMedia, socialMediaMobile
} from "./assets";

const sectionData = [
    {
        name: 'digitalStrategy',
        title: 'DIGITAL STRATEGY',
        subtitle: 'Maximize Growth With Precision Planning',
        paragraphs: [
            <p>Together, we conduct in-depth analysis and research to ensure a
                <b>
                    {""}  seamless brand experience {""}
                </b>
                across all digital media touch-points.
            </p>,
            <p>
                You’ll become the decision-maker with a custom-fit comprehensive
                <b>
                    {""} Digital Strategy {""}
                </b>
                to meet your objectives.
            </p>
        ],
        bulletPoints: ['Brand Positioning', 'Channel Selection', 'Audience Analysis', 'Competitor Research', 'Industry Insights and Benchmarks', 'Territory Planning'],
        backgroundColor: '#4630A1',
        titleColor: '#FFFFFF',
        paragraphColor: '#FFFFFF',
        bulletPointColor: '#FFFFFF',
        subtitleColor: '#FEC260',
        backgroundColorListItem: 'rgba(103, 82, 186, 0.7)',
        borderRadius: 'clamp(3.125rem, 5vw, 4.388rem) 0 0 0',
        sectionImage: digitalStrategy,
        sectionImageMobile: digitalStrategyMobile,
        leftPosition: '40%',
    },
    {
        name: 'paidMedia',
        title: 'PAID MEDIA',
        subtitle: 'Increase Sales And Lower Customer Acquisition Costs',
        paragraphs: [
            <p>
                We have perfected how to bring brands and audiences closer together: we will expertly <b>
                    plan, traffic, manage,
                </b>
                {""} and {""}
                <b>
                    optimize {""}
                </b>
                your paid ads.
            </p>,
            <p>
                Our team makes {""}
                <b>
                    data-driven decisions {""}
                </b>
                while continuously testing, iterating, and scaling wins.
            </p>,
            <p>
                See your brand drive sustainable growth through <b>
                    {""}Paid Media {""}
                </b>
                channels.
            </p>
        ],
        bulletPoints: ['Social Media Ads', 'Google Ads', 'Search (SEM)', 'Programmatic and Display', 'Rewarded Video'],
        backgroundColor: '#FEC260',
        titleColor: '#300F71',
        paragraphColor: '#300F71',
        bulletPointColor: '#300F71',
        subtitleColor: '#F91A92',
        backgroundColorListItem: 'rgba(253, 221, 169, 0.7)',
        borderRadius: '0 0 0 clamp(3.125rem, 5vw, 4.388rem)',
        sectionImage: paidMedia,
        sectionImageMobile: paidMediaMobile,
        leftPosition: '40%',
    },
    {
        name: 'creativeServices',
        title: 'CREATIVE SERVICES',
        subtitle: 'Creative Solutions That Level Up Your Brand Game',
        paragraphs: [
            <p>Our <b>Creative Services</b> don’t just make things look pretty. Our team uses data to guide us!
            </p>,
            <p>
                <b>How do we make brands shine?</b> By turning the arts into a numbers game with top-performing creative content.
            </p>
        ],
        bulletPoints: ['Graphic Design', 'Illustration', '2D Animation', 'Video Editing and Compositing', 'Copywriting and Conceptualizing'],
        backgroundColor: '#6851B9',
        titleColor: '#FFFFFF',
        paragraphColor: '#FFFFFF',
        bulletPointColor: '#FFFFFF',
        subtitleColor: '#FEC260',
        backgroundColorListItem: 'rgba(137, 118, 211, 0.7)',
        borderRadius: '0 clamp(3.125rem, 5vw, 4.388rem) 0 0',
        sectionImage: creativeServices,
        sectionImageMobile: creativeServicesMobile,
        leftPosition: '40%',
    },
    {
        name: 'socialMedia',
        title: 'SOCIAL MEDIA MANAGEMENT',
        subtitle: 'Getting Community Right',
        paragraphs: [
            <p>
                Building relationships between brand and consumer has never been more critical.
            </p>,
            <p>
                Our effective recipe captures social media audiences. We combine our acclaimed creative with <b>in-house social media expertise!</b> Our creators produce thumb-stopping content to <b>grow</b> your community and keep them <b>engaged.</b>
            </p>
        ],
        bulletPoints: ['Community Research', 'Content Calendars and Reporting', 'Community Management and Engagement', 'Content Creation', 'Influencer Marketing'],
        backgroundColor: '#F91A92',
        titleColor: '#FFFFFF',
        paragraphColor: '#FFFFFF',
        bulletPointColor: '#FFFFFF',
        subtitleColor: '#FEC260',
        backgroundColorListItem: 'rgba(248, 108, 183, 0.7)',
        borderRadius: '0 0 clamp(3.125rem, 5vw, 4.388rem) 0',
        sectionImage: socialMedia,
        sectionImageMobile: socialMediaMobile,
        leftPosition: '40%',
    },
];

export default sectionData;