import React from "react";
import styles from "../Navbar.module.css";
import { Link } from "react-scroll";
import { linkedIn} from "../../../../assets";
import CloseButton from "./CloseButton";

const MenuOpened = ({ handleCloseMenu, scrollToTop }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full lg:hidden">
      <CloseButton
        handleCloseMenu={handleCloseMenu}
        scrollToTop={scrollToTop}
      />
      <div className="flex flex-col items-center justify-center space-y-5 w-full">
        <ul className="navItemsFont">
          <Link
            to="digitalStrategy"
            name="digitalStrategy"
            smooth={true}
            duration={500}
            onClick={handleCloseMenu}
          >
            <li>DIGITAL STRATEGY</li>
          </Link>
          <Link
            onClick={handleCloseMenu}
            to="paidMedia"
            name="paidMedia"
            smooth={true}
            duration={500}
          >
            <li>PAID MEDIA</li>
          </Link>
          <Link
            onClick={handleCloseMenu}
            to="creativeServices"
            name="creativeServices"
            smooth={true}
            duration={500}
          >
            <li>CREATIVE SERVICES</li>
          </Link>
          <Link
            onClick={handleCloseMenu}
            to="socialMedia"
            name="socialMedia"
            smooth={true}
            duration={500}
          >
            <li>SOCIAL MEDIA</li>
          </Link>
          <Link
            onClick={handleCloseMenu}
            to="contactUs"
            name="contactUs"
            smooth={true}
            duration={500}
          >
            <li>
              <div className={styles.contactButton}>CONTACT US</div>
            </li>
          </Link>
        </ul>
      </div>
      <div
        className={`bottom-5 absolute flex flex-col gap-1`}
        onClick={handleCloseMenu}
      >
        <p className="text-[#fec260] font-bold text-lg sm:block hidden">
          An{" "}
          <span className="text-[#fff]">
            <a
              href="https://www.influencemobile.com/"
              target="_blank"
              rel="noreferrer"
              className="border-b-[1px] border-b-transparent hover:border-b-[--gold] transition-colors duration-500"
            >
              INFLUENCE MOBILE
            </a>
          </span>{" "}
          Company
        </p>
        <div className="flex justify-center items-end gap-5">
          <div>
            <a
              href="https://www.linkedin.com/company/blind-ferret"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedIn} alt="linkedIn" />
            </a>
          </div>
          <div>
            <Link to="contactUs" name="contactUs" smooth={true} duration={500} onClick={handleCloseMenu}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="17"
                  viewBox="0 0 20.948 16.459"
                >
                  <path
                    id="Icon_ionic-md-mail"
                    data-name="Icon ionic-md-mail"
                    d="M21.2,5.625H4.245a2,2,0,0,0-2,2V20.089a2,2,0,0,0,2,2H21.2a2,2,0,0,0,2-2V7.62A2,2,0,0,0,21.2,5.625Zm-.249,4.24-8.23,5.486L4.494,9.865v-2l8.23,5.486,8.23-5.486Z"
                    transform="translate(-2.25 -5.625)"
                    fill="#fff"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuOpened;
