import React from "react";
import { navlogo } from "../../../../assets";

const CloseButton = ({handleCloseMenu, scrollToTop}) => {
  const handleLogoClick = () =>{
    scrollToTop();
    handleCloseMenu();
  }
  return (
    <div className="flex absolute top-4 items-center justify-between lg:px-[12%] md:px-[6%] px-[8%] sm:py-3 py-0 w-full">
      <img src={navlogo} alt="logo" className="h-10" onClick={handleLogoClick}/>
      <button
        onClick={handleCloseMenu}
        className={` py-2`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={26.302}
          height={23.237}
        >
          <g
            fill="#fec260"
            data-name="Group 140"
            transform="translate(-366 -24.952)"
          >
            <rect
              width={30.017}
              height={5.146}
              data-name="Rectangle 120"
              rx={2.573}
              transform="rotate(40 150.375 519.81)"
            />
            <rect
              width={30.017}
              height={5.146}
              data-name="Rectangle 309"
              rx={2.573}
              transform="rotate(140 190.893 85.84)"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default CloseButton;
