import React from "react";
import styles from "./Clients.module.css";
import clients from "../../clients";

const Clients = () => {
  return (
    <section className="pb-14 overflow-hidden max-w-[1920px] mx-auto">
      <h2 className="sectionTitle sm:px-16 px-6 pb-6" data-aos="fade-left">
        CLIENTS
      </h2>
      <h5 className="pb-16 sm:pl-16 pl-6" data-aos="fade-left">
        Don’t Believe Us? <br className="sm:hidden flex" /> Let Our Clients Do
        The Talking.
      </h5>
      <div
        className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-3 gap-3"
      >
        {clients.map((client, index) => (
          <div className={styles.logoFrame} key={index} data-aos="zoom-out-up">
            <img
              src={client.logo}
              alt="client"
              className="h-full object-contain"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Clients;
