import React from "react";
import styles from "./SectionComponent.module.css";
import { Element } from "react-scroll";

const Section = ({
  name,
  title,
  subtitle,
  paragraphs,
  bulletPoints,
  backgroundColor,
  titleColor,
  paragraphColor,
  bulletPointColor,
  subtitleColor,
  backgroundColorListItem,
  borderRadius,
  sectionImage,
  sectionImageMobile,
  leftPosition,
}) => {
  return (
    <section
      style={{ backgroundColor: backgroundColor, borderRadius: borderRadius }}
      className="flex flex-col max-w-[1920px] w-full mx-auto sm:flex-row sm:p-16 py-12 px-6 sm:gap-16 gap-8 items-center relative"
      data-aos="fade-up"
    >
      <Element className="hash-span absolute" name={name}></Element>
      <div
        className="absolute sm:top-[-4.2rem] top-[-1.5rem] z-[2]"
        style={{ left: leftPosition }}
        
      >
        <img
          src={sectionImage}
          alt="digitalStrategy"
          className="w-[22vw] sm:block hidden max-w-[350px]"
          data-aos="zoom-in-down"
        />
        <img
          src={sectionImageMobile}
          alt="imageMobile"
          className="sm:hidden block w-[43vw] ml-9"
        />
      </div>
      <div style={{ flex: 1 }} className="flex flex-col sm:gap-5 gap-3">
        <h2
          style={{ color: titleColor }}
          className={`${title==="SOCIAL MEDIA MANAGEMENT"? "w-3/4":"w-1/2"} mb-4 sm:w-full relative z-10`}
          data-aos="fade-right"
        >
          {title}
        </h2>
        <h3
          style={{ color: subtitleColor }}
          className={`${subtitle==="Getting Community Right"? "w-1/4":"w-1/2"} text-balance mb-5 sm:w-full w-1/2 relative z-10`}
          data-aos="fade-right"
        >
          {subtitle}
        </h3>
        {paragraphs.map((paragraph, index) => (
          <div
            key={index}
            style={{ color: paragraphColor }}
            className="sm:max-w-[35vw] w-full"
            data-aos="fade-right"
          >
            {paragraph}
          </div>
        ))}
      </div>
      <div style={{ flex: 1 }} className={styles.bulletPointsDiv}>
        <ul className="flex flex-col gap-4 relative z-1">
          {bulletPoints.map((point, index) => (
            <li
              key={index}
              style={{
                backgroundColor: backgroundColorListItem,
                color: bulletPointColor,
              }}
              data-aos="fade-left"
            >
              {point}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Section;
